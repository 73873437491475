<template>
    <b-card class="card-user" no-body>
            <div class="author">
              <div class="block block-one"></div>
              <div class="block block-three"></div>
              <div class="block block-four"></div>
            </div>
            <template v-slot:header>
                <h5 class="title" ><i class="now-ui-icons mr-2"><svgicon class="icon" icon="item-status"/></i>{{t('Services Status')}}</h5>
            </template>

            <template >
                <b-row cols="12" style="width: 100%;" >
                    <b-col cols="4" v-for="(service, index) in services" v-bind:key="service.name">
                        <b-row  no-gutters class="mb-3 px-3"  >
                            <b-col cols="6" class="form-group has-label pt-2 mb-0 mr-2 " >
                                <h5 class="mx-auto my-auto float-right" style="white-space: nowrap; ">{{ t(service.name) }}</h5>
                            </b-col>
                            <b-col class="ml-1 float-left" >
                                <n-switch v-model="services[index].enabled" :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/ds/advanced-configuration' }]"/>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </template>
    </b-card>
</template>

<script>

import { Switch } from '@/components';

export default {
    name: 'dns-status',
    components: {
        [Switch.name]: Switch
    },
    data() {
        return {
            updating: false,
            services :  [],
            oldServices : [],
            original : []
        }
    },
    methods:{
        getDNService(servicename){
            return servicename.replace("enable_","");
        },
        checkCFServices(servicename){
            return (servicename === "safe_search" || servicename === "quiet_time")
        },
        invalidService(servicename){
            return (servicename === "safe_search" || servicename === "quiet_time" || servicename === "anti_phishing" || servicename == "ads_free")
        },
        setValue:function(){
            this.$data.oldServices = _.cloneDeep(this.$data.services);
        },

        refresh(wait){
            let _vm = this
            this.updating = true;
            this.$store.dispatch('dnssecure/dnsstatus/getDNSState')
            .then( res => {
                this.$data.original = _.cloneDeep(res)
                _vm.services = []
                const servicesDS = this.$store.getters['settings/getServicesDS'];
                Object.keys(res).forEach(service => {
                    if( //(!!servicesDS.find(a => a.includes(this.getDNService(service))) ||
                        //(!!servicesDS.find(a => a.includes("content_filtering")) && this.checkCFServices(this.getDNService(service))  )   ) &&
                        service.toLowerCase().indexOf("enable") == 0 && !this.invalidService(this.getDNService(service)) )
                        _vm.services.push({"name" : service, "enabled" : res[service]});
                });
                _vm.setValue();
                _vm.updating = false
            })
            .catch( err => {
                _vm.updating = false
                console.log("error:" + err)
            });
        }
    },

    watch:{
        services: {
            handler: function (after, before) {
                var vm=this;

                let changed = after.filter( function( p, idx ) {
                    return Object.keys(p).some( function( prop ) {
                    return p[prop] !== vm.$data.oldServices[idx][prop];
                    })
                })
                vm.setValue();

                if( changed.length > 0  ){

                    //let newObj = {};
                    before.forEach((value, index) => {
                        let name = value.name
                        let enabled = value.enabled
                        this.$data.original[name] = enabled;
                    });

                    this.$data.original["enable_anti_phishing"] = this.$data.original["enable_anti_malware"]

                    this.$store.dispatch('dnssecure/dnsstatus/updateDNSState', this.$data.original)
                    .then( res => {
                       this.refresh(0)
                    })
                    .catch( err => {
                        this.refresh(0)
                        console.log("error:" + err)
                    });
                }
            },
            deep: true
        }
    },

    mounted() {
        this.refresh(0);
    }
}

</script>
